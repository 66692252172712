<template>
  <v-app>
    <header-component v-show="pantalla !== 'onboarding'"/>
    <v-main v-if="!modal2 && !modal1" class="fondoBlanco">
      <v-container class="pa-0">
        <router-view/>
        <icon-help></icon-help>
      </v-container>
    </v-main>
    <v-dialog persistent v-model="modal1" :width="$vuetify.breakpoint.smAndUp ? '424':'312'" :height="$vuetify.breakpoint.smAndUp ? '250':'192'">
      <modal-component :mensaje="mensajeModal"></modal-component>
    </v-dialog>
    <v-dialog persistent v-model="modal2" :width="$vuetify.breakpoint.smAndUp ? '424':'312'" :height="$vuetify.breakpoint.smAndUp ? '250':'192'">
      <modal-component v-if="usuario" :mensaje="mensajeModal" :usuario="usuario"></modal-component>
    </v-dialog>
    <modal-despachando style="z-index: -2" v-show="modal2"></modal-despachando>
    <v-img src="./static/dedo.svg" style="z-index: -2" v-show="modal2"></v-img>
  </v-app>
</template>
<script>
import HeaderComponent from '@/components/HeaderComponent.vue';
import IconHelp from '@/components/IconHelp.vue';
import ModalComponent from '@/components/ModalComponent.vue';
import ModalDespachando from '@/components/ModalDespachando.vue';

export default {
  components: {
    ModalDespachando,
    ModalComponent,
    IconHelp,
    HeaderComponent,
  },
  data() {
    return {
      modal1: true,
      modal2: false,
      modalDespachando: true,
      mensajeModal: 'Identificando usuario',
    };
  },
  computed: {
    pantalla() {
      return this.$store.state.pantalla;
    },
    productos() {
      return this.$store.state.productos;
    },
    usuario() {
      return localStorage.getItem('user');
    },
  },
  methods: {
    // Trae hora del server
    getHour() {
      this.axios.get(`${process.env.VUE_APP_BACK}/utc_timestamp`)
        .then((response) => {
          // Trae la hora del server
          this.horaServer = this.$moment(response.data.utc_timestamp)
            .format('HH:mm:ss.SSS');
          // Calcula la hora de referencia.
          // Para eso, a la hora del server le sumo la duracion que tarda la peticion
          this.horaReferencia = this.$moment(response.data.utc_timestamp)
            .add(response.duration / 2, 'milliseconds')
            .format('HH:mm:ss.SSS');
          // Traigo la hora del dispositivo
          // Delta: Diferencia entre la hora de referencia con la hora del dispositivo
          const start = this.$moment(this.horaReferencia, 'HH:mm:ss.SSS');
          const end = this.$moment(this.$moment.utc(), 'HH:mm:ss.SSS');
          this.$store.commit('SET_DELTA', this.$moment(start)
            .diff(end));
        });
    },
    getDelay() {
      this.axios.get(`${process.env.VUE_APP_BACK}/delay`)
        .then((response) => {
          console.log('response delay:', response.data.delay);
          this.$store.commit('SET_DELAY', response.data.delay);
        });
    },
    guardar() {
      if (!localStorage.getItem('user')) {
        const randomNumber = Math.floor(1000 + Math.random() * 9000);
        const userName = `Expo${randomNumber}`;
        localStorage.setItem('user', userName);
      }
    },
  },
  created() {
    this.getHour();
    this.getDelay();
    // this.guardar();
    localStorage.setItem('user', 'Webinar');
  },
  mounted() {
    setTimeout(() => {
      this.modal1 = false;
      this.modal2 = true;
      this.mensajeModal = 'Usuario identificado como';
      setTimeout(() => {
        this.modal2 = false;
      }, 2000);
    }, 4000);
    const prods = [
      /* eslint-disable global-require */
      {
        index: 1,
        habilitado: true,
        nombre: 'Infinia Diesel',
        contrato: 'Webinar',
        cupo: 10000,
        src: require('./static/INFINIA_SMART_CONTRACT.png'),
        width: '102.74px',
        height: '11.84px',
        num_contract: `${Math.floor(1000 + Math.random() * 9000)} ${Math.floor(1000 + Math.random() * 9000)}`,
      },
      {
        index: 2,
        habilitado: true,
        nombre: 'Extravida',
        contrato: 'Contrato Abierto',
        cupo: 10000,
        src: require('./static/EXTRAVIDA_SMART_CONTRACT.png'),
        width: '108.51px',
        height: '12.5px',
        num_contract: `${Math.floor(1000 + Math.random() * 9000)} ${Math.floor(1000 + Math.random() * 9000)}`,
      },
    ];
    if (localStorage.getItem('SET_PRODUCTS')) {
      const productos = JSON.parse(localStorage.getItem('SET_PRODUCTS'));
      if (productos[0].contrato) {
        this.$store.commit('SET_PRODUCTS', JSON.parse(localStorage.getItem('SET_PRODUCTS')));
      } else {
        console.log('entra al else');
        localStorage.setItem('SET_PRODUCTS', JSON.stringify(prods));
        this.$store.commit('SET_PRODUCTS', prods);
      }
    } else {
      localStorage.setItem('SET_PRODUCTS', JSON.stringify(prods));
      this.$store.commit('SET_PRODUCTS', prods);
    }
    if (localStorage.getItem('SET_PRODUCT_SELECT')) {
      this.$store.commit('SET_PRODUCT_SELECT', JSON.parse(localStorage.getItem('SET_PRODUCT_SELECT')));
    }
    if (localStorage.getItem('SET_TRANSACTION_SELECT')) {
      this.$store.commit('SET_TRANSACTION_SELECT', JSON.parse(localStorage.getItem('SET_TRANSACTION_SELECT')));
    }
  },
};
</script>
<style lang="scss">

.fondoBlanco {
  background: #FAFBFF !important;
  margin-top: 56px;
}
@media (min-width: 960px) {
  .container {
      max-width: 100vw;
  }
}
</style>
