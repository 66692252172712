import Vue from 'vue';
import Vuex from 'vuex';
import transactionsProduc1 from '@/transacciones/transactionsProduc1';
import transactionsProduc2 from '@/transacciones/transactionsProduc2';
import abastecimientoInfinia from '@/abastecimientos/abastecimientos1';
import abastecimientoExtraVida from '@/abastecimientos/abastecimientos2';

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    delta: null,
    delay: null,
    abastecimientoInfinia,
    abastecimientoExtraVida,
    transactionsProduc1,
    transactionsProduc2,
    transactionSelected: null,
    transaction: {},
    fechaTicket: null,
    pantalla: 'bienvenida',
    producto_seleccionado: {
      nombre: '',
      cupo: 0,
      num_contract: null,
    },
    /* eslint-disable global-require */

    stock: [
      {
        id: 1,
        img: require('../static/INFINIA_PRODUCT.png'),
        width: '145px',
        height: '25px',
        capacidad: 50000.0,
        volumen: 0,
        temp: '25°C',
        agua: 'Sin agua - No detectado',
        proxAbastecimiento: '',
      },
      {
        id: 2,
        img: require('../static/EXTRAVIDA.png'),
        width: '134px',
        height: '23px',
        capacidad: 5000.0,
        volumen: 0,
        temp: '25°C',
        agua: 'Sin agua - No detectado',
        proxAbastecimiento: '',
      },
      {
        id: 3,
        img: require('../static/AZUL_PRODUCT.png'),
        width: '82px',
        height: '23px',
        capacidad: 2000.0,
        volumen: 1800.0,
        temp: '25°C',
        agua: 'Sin agua - No detectado',
        proxAbastecimiento: undefined,
      },
    ],
    productos: null,
    vehiculo: null,
    inputs_ticket: null,
    num_contract: Math.floor(1000 + Math.random() * 9000000),
  },
  getters: {},
  mutations: {
    SET_DELTA(state, data) {
      state.delta = data;
    },
    SET_DELAY(state, data) {
      state.delay = data;
    },
    SET_PANTALLA(state, data) {
      state.pantalla = data;
    },
    SET_CUPO_PRODUC_1(state, data) {
      Object.assign(state.productos[0], data);
    },
    SET_CUPO_PRODUC_2(state, data) {
      Object.assign(state.productos[1], data);
    },
    SET_VEHICULO(state, data) {
      state.vehiculo = data;
    },
    SET_PRODUCT_SELECT(state, data) {
      state.producto_seleccionado = data;
    },
    SET_TRANSACTION_SELECT(state, data) {
      state.transactionSelected = data;
    },
    SET_INPUTS(state, data) {
      state.inputs_ticket = data;
    },
    SET_TRANSACTION(state, data) {
      state.transaction = data;
    },
    SET_FECHA_TICKET(state, data) {
      state.fechaTicket = data;
    },
    SET_PRODUCTS(state, data) {
      state.productos = data;
    },
    SET_VOLUME_PRODUCT1(state, data) {
      state.stock[0].volumen = data;
    },
    SET_VOLUME_PRODUCT2(state, data) {
      state.stock[1].volumen = data;
    },
    SET_PROX_ABASTE_1(state, data) {
      state.stock[0].proxAbastecimiento = data;
    },
    SET_PROX_ABASTE_2(state, data) {
      state.stock[1].proxAbastecimiento = data;
    },

  },
  actions: {},
  modules: {},
});
